<template>
	<div v-if="Loading" class="d-flex">
		<v-progress-circular class="mx-auto my-5" color="primary" indeterminate />
	</div>
	<div v-else class="data-table">
		<v-snackbar timeout="-1" right v-model="snackbar" color="primary" max-width="480">
			<span class="text-uppercase white--text" style="font-size: 1.25rem">
				<b>В этот день, утром проводится мастер-класс, зона катка будет ограничена.</b>
			</span>
		</v-snackbar>
		<template v-if="allowedDates.length == 0">
			<v-alert color="primary" class="white--text" type="info" border="bottom">Расписание недоступно. Продажи начнутся с 30 ноября</v-alert>
		</template>
		<template v-else>
			<v-menu min-width="auto">
				<template #activator="{ on, attrs }">
					<v-text-field
						v-on="on"
						v-bind="attrs"
						prepend-icon="mdi-calendar"
						readonly
						outlined
						dense
						hide-details
						placeholder="Выберите дату"
						v-model="dateFormatted"
						class="mb-5"
					/>
				</template>
				<v-date-picker
					locale="ru-ru"
					:first-day-of-week="1"
					v-model="DatePickerModel"
					:min="minDate"
					:allowed-dates="allowed"
					:max="maxDate"
				/>
			</v-menu>

			<v-alert v-if="Data.length == 0 && DatePickerModel" type="info"> Сеансов на эту дату нет </v-alert>
			<template v-else-if="Data.length"> </template>

			<div class="headers" :style="`border-block: 1px solid ${theme.primary}`">
				<div class="col">Сеансы</div>
				<div class="col">Обработка льда</div>
			</div>
			<div class="data">
				<div class="item" v-for="(Item, index) in Data" :key="index">
					<div class="col">{{ Item.time }}</div>
					<div class="col">{{ Item.pause }}</div>
				</div>
			</div>

			<!-- <v-btn height="72" width="100%" max-width="400" color="primary" class="rounded-0" @click="OpenWP">Купить билет на каток</v-btn> -->

			<v-row :class="isMobile ? 'd-flex flex-column-reverse' : null">
				<v-col cols="12" md="6">
					<v-btn height="72" width="100%" color="primary" class="rounded-0 mb-4" @click="OpenWP">Купить билет на каток</v-btn>
					<!-- <v-btn height="72" width="100%" color="black" class="white--text rounded-0 d-block" @click="OpenTELE2">
						Купить билет по промокоду TELE2
					</v-btn>-->
				</v-col>
				<!-- <v-col cols="12" md="6">
					<v-alert type="error" style="font-size: 1.25rem" class="mb-0">
						В связи с введением пропускного режима на Красную площадь, просим Вас заблаговременно прибывать на каток.
					</v-alert>
				</v-col> -->
			</v-row>
		</template>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';
import EShopWidget from './../../codebase/widget.script';
import GUMService from './../../codebase/gum-service';
import Session from './../../codebase/Session';

import { DATE_SALE_START, DATE_SALE_END, DATE_SALE_BEGIN } from './../../main';

export default Vue.extend({
	name: 'DataTable',

	data: () => ({
		Data: [] as Array<Session>,
		Loading: false,
		Today: new Date(),
		Service: new GUMService(),
		Mindate: DATE_SALE_START as Date,
		Maxdate: DATE_SALE_END as Date,
		dateBegin: DATE_SALE_BEGIN as Date,
		DatePickerModel: '',
		saleMaxDepth: 7,
		saleMinDepth: 3,
		DisabledDates: [] as Array<string>,
	}),
	watch: {
		async DatePickerModel(): Promise<void> {
			if (!this.DatePickerModel) return;
			this.Loading = true;

			let date = this.dateFormatted;
			this.Data = await this.Service.GetSessions(date);
			this.Loading = false;
		},
	},
	computed: {
		isMobile: function (): boolean {
			return this.$vuetify.breakpoint.xsOnly;
		},
		theme: function () {
			return this.$vuetify.theme.currentTheme;
		},
		snackbar: function (): boolean {
			return false;//this.occupiedDates.includes(this.DatePickerModel);
		},
		occupiedDates: function (): Array<string> {
			let yearCurrent = DATE_SALE_START.getFullYear();
			let yearNext = DATE_SALE_END.getFullYear();
			return [
				`${yearCurrent}-12-02`,
				`${yearCurrent}-12-03`,
				`${yearCurrent}-12-09`,
				`${yearCurrent}-12-10`,
				`${yearCurrent}-12-16`,
				`${yearCurrent}-12-17`,
				`${yearCurrent}-12-23`,
				`${yearCurrent}-12-24`,
				`${yearNext}-01-13`,
				`${yearNext}-01-14`,
				`${yearNext}-01-20`,
				`${yearNext}-01-21`,
				`${yearNext}-01-27`,
				`${yearNext}-01-28`,
				`${yearNext}-02-03`,
				`${yearNext}-02-04`,
				`${yearNext}-02-10`,
				`${yearNext}-02-11`,
				`${yearNext}-02-17`,
				`${yearNext}-02-18`,
				`${yearNext}-02-24`,
				`${yearNext}-02-25`,
			];
		},
		allowedDates: {
			get(): Array<Date> {
				if (this.Today.getTime() < this.dateBegin.getTime()) return [];
				console.log(this.saleDates);				
				return this.saleDates.filter((d: Date) => {
					// If the date is disabled
					let str = d.toLocaleDateString();
					if (this.DisabledDates.includes(str)) return false;

					// If date is outside maxDate limit
					let ts = new Date(d).getTime();
					if (ts > new Date(this.Maxdate).getTime()) return false;

					// If the date is found in season
					let find = this.season.find((date) => {
						return d.toLocaleDateString() == date.toLocaleDateString();
					});
					return find;
				});
			},
		},
		season: {
			get(): Array<Date> {
				let dif = this.datesBetween(this.Mindate, this.Maxdate);
				return dif;
			},
		},
		saleDates: {
			get(): Array<Date> {
				return this.datesBetween(this.Today, this.dateOnDepth);
			},
		},
		saleDepth: {
			get: function () {
				let yearCurrent = DATE_SALE_START.getFullYear();
				let yearNext = DATE_SALE_END.getFullYear();
				// Debug purposes
				//if (this.Today < new Date(`${yearCurrent}/11/25`)) return 15;
				// Уменьшение глубины продаж с 23 декабря по 6 января
				let reduceDateA = new Date(`${yearCurrent}/12/23`);
				let reduceDateB = new Date(`${yearNext}/01/05`);
				if (this.Today.getTime() > reduceDateA.getTime() && this.Today.getTime() < reduceDateB.getTime()) return this.saleMinDepth;
				return this.saleMaxDepth;
			},
		},
		dateOnDepth: function (): Date {
			return new Date(new Date(this.Today).setDate(new Date(this.Today).getDate() + this.saleDepth));
		},
		minDate: {
			get(): string {
				let d: Date = new Date(this.Today);
				if (d.getTime() > this.Mindate.getTime()) {
					return this.localizeDate(d);
				} else {
					return this.localizeDate(this.Mindate);
				}
			},
		},
		maxDate: {
			get: function (): string {
				// Ограничить максимальную дату 24ым декабря, вплоть до 23 декабря
				// т.е. 22го декабря ограничение будет работать, а 23го снимется
				let yearCurrent = DATE_SALE_START.getFullYear();
				let reduceByDate = new Date(`${yearCurrent}/12/23`);
				let reducyToDate = new Date(`${yearCurrent}/12/24`);
				if (this.Today < reduceByDate) return this.localizeDate(reducyToDate);
				// / Ограничение...

				return this.localizeDate(this.lastDate);
			},
		},
		lastDate: function (): Date {
			return this.allowedDates[this.allowedDates.length - 1];
		},
		dateFormatted: {
			get: function () {
				return this.DatePickerModel.replace('/', '-').split('-').reverse().join('.');
			},
		},
	},
	created(): void {
		let script = document.createElement('script');
		script.src = 'widget_shop.js?v=1.0.1';
		script.type = 'text/javascript';
		document.head.append(script);

		this.DatePickerModel = this.minDate;
		let maxDate = new Date(this.maxDate).toLocaleDateString('ru-RU');
		let disableddates = this.DisabledDates;
	},
	methods: {
		/**
		 * Converts date object to YYYY-MM-DD
		 * @param date - Date to "localize"
		 */
		localizeDate: function (date: Date): string {
			return date.toLocaleDateString('ru-RU').replaceAll('/', '.').split('.').reverse().join('-');
		},
		datesBetween(date1: Date, date2: Date): any {
			let sorted = [new Date(date1), new Date(date2)]
				.map((d) => d.getTime())
				.sort()
				.map((d) => new Date(d));
			let dib: any[] = [];
			const LAST = sorted[sorted.length - 1];
			for (let d = new Date(sorted[0]); d < LAST; d.setDate(d.getDate() + 1)) {
				dib.push(new Date(new Date(d).setHours(0, 0, 0, 0)));
			}
			return dib;
		},
		OpenWP(): void {
			if (this.DatePickerModel) {
				(window as unknown as EShopWidget).widgetShopOpen(1);
			}
		},
		OpenTELE2() {
			if (this.DatePickerModel) {
				(window as unknown as EShopWidget).widgetShopOpen(1);
			}
		},
		allowed(date: string): boolean {
			// date format: 2023-01-31
			let stringDate = new Date(date).toLocaleDateString('ru-RU');
			if (this.DisabledDates.includes(stringDate)) return false;
			let dd = new Date(date).toLocaleDateString();
			if (this.allowedDates.find((d) => d.toLocaleDateString() === dd)) return true;
			return false;
		},
	},
});
</script>

<style lang="less" scoped>
.data-table {
	margin-bottom: 1rem;

	.headers {
		display: flex;

		& > div {
			flex: 1;
			padding-block: 8px;

			&:last-child {
				flex: 2;
			}
		}
	}

	.data {
		.item {
			display: flex;

			.col {
				flex: 1;

				&:last-child {
					flex: 2;
				}
			}
		}
	}
}
</style>
../../codebase/widget.script