import Vue from 'vue';
import App from './App.vue';
//import App from './AppYarmarka.vue';
//import App from './AppFigureAlina.vue';
//import App from './AppFHR.vue';
//import App from './AppFigure.vue';
//import App from './AppHockey.vue';

import router from './router';
import vuetify from './plugins/vuetify';

export const WORKHOURS = 'с 09:00 до 23:30';
export const CONTACT_MAIL = 'info.katok@gum.ru'; // info.katok@gum.ru gum-katok@gum.ru
export const CONTACT_PHONE = '+7 (495) 620-3227';

// Calculate this season's sale start date's full year and keep it yesteryear until march
// For example sale start date will stay 2023/11/29 in 2024/01/15
const TODAY = new Date();
let year = TODAY.getFullYear();
if (TODAY.getMonth() >= 3) year = TODAY.getFullYear();
else year = TODAY.getFullYear() - 1;
// Begin means ON/OFF switch. START means DATE FROM. END means DATE UNTIL.
// КАТОК РАБОТАЕТ С 30 НОЯБРЯ по 29 ФЕВРАЛЯ
export const DATE_SALE_BEGIN = new Date(`${year}/11/30`);
export const DATE_SALE_START = new Date(`${year}/12/06 09:00`);
export const DATE_SALE_END = new Date(`${year + 1}/03/10 23:30`);
// Sale related shenanigans

Vue.config.productionTip = false;

new Vue({
	router,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
